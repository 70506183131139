:root {
    --neBlue: #050a30;
    --neDarkBlue: #00001a;
    --neLightBlue: #f4f6fc;
    --neGrey: #D3D3D3;
}

.pnf-logo {
    position: absolute;
    top: 16px;
}

.page-not-found {
    margin: 15% 0%;
}

.page-not-found-icon {
    font-size: 120px;
    color: black;
    margin-bottom: 30px;
}


@keyframes slide-colors {
    from {
        background-position: 0% 50%;
    }

    to {
        background-position: 100% 50%;
    }
}

.break-text {
    line-height: 1.6;
    background: linear-gradient(90deg, var(--neGrey), var(--neBlue), var(--neDarkBlue), cyan, blue);
    background-size: 200%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: slide-colors 5s linear infinite;
}

.work-card {
    border: 1px solid var(--neDarkBlue);
    padding: 20px;
    background-color: var(--neLightBlue);
}

.work-card:hover {
    cursor: pointer;
}
.work-card img {
    width: 100%;
    height: 300px;
}

.work-name:hover {
    transform: scale(1.05);
    cursor: pointer;
    color: var(--neDarkBlue);
}

.footer-form input,
.footer-form textarea {
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    background-color: var(--neBlue);
    border: none;
    border-bottom: 1px solid white;
    color: white;
}

.footer-form input:focus,
.footer-form textarea:focus {
    outline: none;
}

.footer-l h6 {
    color: var(--neGrey);
    font-weight: light;
}

.about_card {
    border: 1px solid;
    border-radius: 5%;
    padding: 15px 20px;
}

.about_card img {
    width: 150px;
    margin: 0 auto 60px auto;
}

.about_card h4 {
    font-size: 28px;
    margin-bottom: 15px;
    color: var(--neBlue);
}

.about_card p {
    font-size: 17px;
    line-height: 1.5;
}

.about_card:hover {
    cursor: pointer;
}

.servicess {
    border: 1px solid var(--neBlue);
    border-top: none;
    margin-top: 20px;
}

.servicess p {
    border-top: 1px solid var(--neBlue);
    padding: 20px;
    margin-bottom: -1px;
}

.servicess p:hover {
    cursor: pointer;
    background-color: var(--neDarkBlue);
    color: var(--neGrey);
}

.servicess-sel {
    background-color: var(--neDarkBlue);
    color: var(--neGrey);
}

.contact-icons img {
    width: 90px;
}

.contact-icons img:hover {
    transform: scale(1.10);
    cursor: pointer;
}

.terms h5 {
    margin-top: 40px;
    font-weight: 600;
}