@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.layout {
  background-color: #fff;
  font-family: "Lota Grotesque, Light", sans-serif;
  line-height: 1.5;
  font-weight: lighter;
  color: #00001a;
}

code {
  font-family: "Lota Grotesque, Light", sans-serif;
}
